import { of } from "rxjs";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
/**
 * Сервис по работе с конфигурациями окружения приложения.
 */
var ConfigurationService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса по работе с конфигурациями окружения приложения.
     *
     */
    function ConfigurationService() {
        this.configuration$ = of(environment);
    }
    //endregion
    //region Public
    /**
     * Загружает конфигурацию окружения приложения.
     *
     * @return Конфигурация окружения приложения.
     */
    ConfigurationService.prototype.loadConfigurations = function () {
        return this.configuration$;
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
