import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LoginService = /** @class */ (function () {
    function LoginService(httpClient) {
        this.httpClient = httpClient;
    }
    /**
     * Вызывает логин пользователя.
     * @param {User} user Модель пользователя для логина.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    LoginService.prototype.signIn = function (user) {
        return this.httpClient.post("/api/v1/login", user)
            .pipe(catchError(function (err) { return throwError(err.error); }));
    };
    /**
     * Вызывает регистрацию пользователя.
     * @param {User} user Модель пользователя для регимтрации.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    LoginService.prototype.signUp = function (user) {
        return this.httpClient.post("/api/v1/login", user)
            .pipe(catchError(function (err) { return throwError(err.error); }));
    };
    /**
     * Вызывает логин пользователя.
     * @param {User} user Модель пользователя для логина.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    LoginService.prototype.resendVerification = function (user) {
        return this.httpClient.post("/api/v1/registration/resend", user)
            .pipe(catchError(function (err) { return throwError(err.error); }));
    };
    /**
     * Отправка запроса на воотсановление пароля.
     * @param user пользователь для которого нужно восстановить пароль.
     */
    LoginService.prototype.sendRestoreRequest = function (user) {
        return this.httpClient.post("/api/v1/restore/password", user)
            .pipe(catchError(function (err) { return throwError(err.error); }));
    };
    /**
     * Отправка запроса на воотсановление пароля.
     * @param user пользователь для которого нужно восстановить пароль.
     * @param id токена запроса смены пароля.
     */
    LoginService.prototype.changePassword = function (user, id) {
        return this.httpClient.post("/api/v1/restore/password/" + id, user)
            .pipe(catchError(function (err) { return throwError(err.error); }));
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
