import { Title } from "@angular/platform-browser";
import { version } from "src/environments/version";
/**
 * Комонент страницы с версией UI-приложения.
 */
var VersionComponent = /** @class */ (function () {
    //#region Ctor
    function VersionComponent(_title) {
        this._title = _title;
    }
    //#endregion
    //#region Hooks
    VersionComponent.prototype.ngOnInit = function () {
        this._title.setTitle("Entera - version");
    };
    VersionComponent.prototype.ngOnDestroy = function () {
        this._title.setTitle("");
    };
    Object.defineProperty(VersionComponent.prototype, "version", {
        //#endregion
        //#region Getters and Setter
        /**
         * Возвращает версию UI приложения.
         */
        get: function () {
            return version;
        },
        enumerable: true,
        configurable: true
    });
    return VersionComponent;
}());
export { VersionComponent };
