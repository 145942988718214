import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[enteraComplexity]',
    providers: [{provide: NG_VALIDATORS, useExisting: ComplexityValidatorDirective, multi: true}]
})
/**
 * Валидатор проверки сложности пароля.
 */
export class ComplexityValidatorDirective implements Validator {

    private readonly DIFFERENT_LETTERS_NUMBER = 6;

    @Input()
    enteraSameInputFields: string[];

    @Input()
    enteraSameInputErrorsFields: string[];

    validate(control: AbstractControl): ValidationErrors | null {

        let result = null;

        if (control && control.value && this.testComplexity(control.value)) {
            result = {"complexity": true}
        }

        return result;
    }

    constructor() {
    }

    private testComplexity(value: string): boolean {

        let result = true;
        if (value.length >= this.DIFFERENT_LETTERS_NUMBER) {

            let count = 0;

            while (value.length > 0) {
                value = value.replace(new RegExp(value[0], 'g'), "");
                count++;
                if (count >= this.DIFFERENT_LETTERS_NUMBER) {
                    result = false;
                    break;
                }
            }
        }

        return result;
    }
}
