import {Directive, Input} from '@angular/core';
import {FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[enteraSameInput]',
    providers: [{provide: NG_VALIDATORS, useExisting: SameInputValidatorDirective, multi: true}]
})
/**
 * Валидатор проверки идентичности ввода полей. Устанавливается на всю форму.
 */
export class SameInputValidatorDirective implements Validator {

    /**
     * Поля который должны быть идентичны.
     */
    @Input()
    enteraSameInputFields: string[];

    /**
     * Поля на дополнительно появиться ошибка валидации.
     */
    @Input()
    enteraSameInputErrorsFields: string[] = [];

    validate(control: FormGroup): ValidationErrors | null {

        let result = null;

        if (control && this.enteraSameInputFields) {
            let same = this.enteraSameInputFields
                .map(name => control.get(name))
                .filter(Boolean)
                .map(fromControl => fromControl.value)
                .every((value, index, array) =>
                    index < 1 || value === array[index - 1]
                );

            if (!same) {
                result = {
                    "notSame": true
                };

                this.enteraSameInputErrorsFields
                    .map(name => control.get(name))
                    .filter(Boolean)
                    .forEach(formControl => formControl.setErrors(
                        {
                            ...formControl.errors,
                            "notSame": true
                        },
                        {emitEvent: true})
                    );


            }

            else {
                this.enteraSameInputErrorsFields
                    .map(name => control.get(name))
                    .filter(Boolean)
                    .forEach(formControl => {
                            let errors = {...formControl.errors};
                            delete errors.notSame;

                            if (!Object.keys(errors).length) {
                                errors = null;
                            }

                            formControl.setErrors(errors, {emitEvent: true})
                        }
                    );
            }
        }

        return result;
    }

    constructor() {
    }

}
