var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { FormGroup, ValidationErrors, Validator } from "@angular/forms";
var SameInputValidatorDirective = /** @class */ (function () {
    function SameInputValidatorDirective() {
        /**
         * Поля на дополнительно появиться ошибка валидации.
         */
        this.enteraSameInputErrorsFields = [];
    }
    SameInputValidatorDirective.prototype.validate = function (control) {
        var result = null;
        if (control && this.enteraSameInputFields) {
            var same = this.enteraSameInputFields
                .map(function (name) { return control.get(name); })
                .filter(Boolean)
                .map(function (fromControl) { return fromControl.value; })
                .every(function (value, index, array) {
                return index < 1 || value === array[index - 1];
            });
            if (!same) {
                result = {
                    "notSame": true
                };
                this.enteraSameInputErrorsFields
                    .map(function (name) { return control.get(name); })
                    .filter(Boolean)
                    .forEach(function (formControl) { return formControl.setErrors(__assign({}, formControl.errors, { "notSame": true }), { emitEvent: true }); });
            }
            else {
                this.enteraSameInputErrorsFields
                    .map(function (name) { return control.get(name); })
                    .filter(Boolean)
                    .forEach(function (formControl) {
                    var errors = __assign({}, formControl.errors);
                    delete errors.notSame;
                    if (!Object.keys(errors).length) {
                        errors = null;
                    }
                    formControl.setErrors(errors, { emitEvent: true });
                });
            }
        }
        return result;
    };
    return SameInputValidatorDirective;
}());
export { SameInputValidatorDirective };
