<!--todo локализация-->
<entera-logo-card class="request-restore-card">
    <div *ngIf="!sent; else sentContent">
        <h1 class="mat-h1 request-restore-card__header">
            {{ "common.recovery.passwordRecovery" | translate }}
        </h1>
        <form (ngSubmit)="onSubmit(requestRestorePassForm)" #requestRestorePassForm="ngForm" name="loginFrom">
            <mat-form-field class="request-restore-card__field">
                <input
                        [(ngModel)]="model.login"
                        name="email"
                        matInput
                        type="email"
                        placeholder="Email"
                        email
                        required
                >
                <mat-error *ngIf="requestRestorePassForm.invalid">
                    {{getError(requestRestorePassForm)}}
                </mat-error>
            </mat-form-field>
            <p class="request-restore-card__note">
                {{ "common.recovery.enterEmail" | translate }}
            </p>
            <button class="request-restore-card__button" type="submit" mat-raised-button color="primary">
                {{ "common.recovery.getLinkToRecovery" | translate }}
            </button>
            <a class="request-restore-card__button request-restore-card__green" mat-button routerLink="/login">{{ "common.loginPage.login" | translate }}</a>
        </form>
    </div>
</entera-logo-card>
<ng-template #sentContent>
    <h1 class="mat-h1 request-restore-card__header">
        {{ "common.recovery.linkSentSuccess" | translate }}
    </h1>
    <p class="request-restore-card__note">
        {{"common.recovery.checkEmailAsk" | translate: { email: model.login } }}
    </p>
</ng-template>
