import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as LogRocket from "logrocket";

/**
 * Сервис для инициализации Logrocket'а.
 */
@Injectable({
    providedIn: "root"
})
export class LogrocketService {
    //region Public

    /**
     * Инициализирует Logrocket.
     *
     * В процессе инициализации передаёт App ID LogRocket'а и идентифицирует пользователя.
     */
    init() {

        if (environment.logRocket && environment.logRocket.enabled) {

            LogRocket.init(environment.logRocket.appId);
        }
    }

    //endregion
}
