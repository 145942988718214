import { ObjectUtils } from "src/app/common/utils/object.utils";
import { IdEnvironment } from "src/environments/id-enviroment.model";

/**
 * Production окружение.
 */
export const environment: IdEnvironment = {

    production: true,
    authServerUrl: 'https://id.entera.pro',
    appServerUrl: 'https://app.entera.pro',
    enteraLandingUrl: 'http://entera.pro',
    global: false,
};

/**
 * Выполняет загрузку конфигурации.
 */
function loadConfig(): void {

    const CONFIG_URL: string = "assets/config/config.json";

    const configRequest: XMLHttpRequest = new XMLHttpRequest();

    function applyConfig() {

        if (configRequest.status < 400) {

            const newEnv = {...environment, ...JSON.parse(configRequest.response)};
            ObjectUtils.freezeInDeep(newEnv, false);
            Object.assign(environment, newEnv);
        }
    }

    configRequest.open("GET", CONFIG_URL, false);
    configRequest.setRequestHeader("Cache-Control", "no-cache");
    configRequest.onload = applyConfig;
    configRequest.send();
}

try {

    loadConfig();
}
catch (e) {

    console.log("Can't load config", e);
}
