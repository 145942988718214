import { I18nMessages } from "src/app/common/models/i18n-messages.js";

import ruMessages from "./ru.json";
import enMessages from "./en.json";

/**
 * I18n-сообщения для русского языка.
 */
export const ru: I18nMessages = {
    lang: "ru",
    messages: ruMessages,
};

/**
 * I18n-сообщения для английского языка.
 */
export const en: I18nMessages = {
    lang: "en",
    messages: enMessages,
};

/**
 * I18n-сообщения на языках, которые поддерживает приложение.
 * Данный массив используется для загрузки сообщений в TranslateService.
 */
export const langs = [
    ru, en,
];
