import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { I18nMessages } from "../models/i18n-messages";

/**
 * Сервис для работы с языками.
 */
@Injectable({
    providedIn: "root"
})
export class LangService {
    //region Public

    /**
     * Выполняет загрузку заданных сообщений в сервис для работы с i18n-сообщениями.
     * 
     * @param translateService Сервис для работы с i18n-сообщениями.
     * @param langs Сообщения на разных языках.
     */
    loadTranslations(translateService: TranslateService, langs: I18nMessages[]): void {

        for (const lang of langs) {

            translateService.setTranslation(lang.lang, lang.messages, true);
        }
    }

    //endregion
}
