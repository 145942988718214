import { NgModule } from "@angular/core";
import { environment } from "src/environments/environment";
import { providers } from "../common/providers";
import { VersionComponent } from "./containers/version/version.component";
import { guards } from "./guards";
import { modules } from "./modules";
import { RootComponent } from "./root.component";

@NgModule({
    imports: [
        ...modules,
    ],
    declarations: [
        RootComponent,
        VersionComponent,
    ],
    providers: [
        providers,
        ...guards,
    ],
    bootstrap: [RootComponent]
})
export class RootModule {

    constructor() {

        const gtagEnabled = environment.gtag && environment.gtag.enabled;

        const windowAsAny: any = window;
        windowAsAny.dataLayer = windowAsAny.dataLayer || [];
        windowAsAny.gtag = function () {
            if (gtagEnabled) {
                windowAsAny.dataLayer.push(arguments);
            }
        };

        if (gtagEnabled) {

            const gtagScript = document.createElement("script");
            gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.gtag.stream;
            gtagScript.async = true;
            document.body.appendChild(gtagScript);

            windowAsAny.gtag("js", new Date());
            windowAsAny.gtag(
                "config",
                environment.gtag.stream,
                { send_page_view: false, debug_mode: gtagEnabled && environment.gtag.debug}
            );
        }
    }
}
