<entera-logo-card class="change-password-card">
    <div *ngIf="!(sent || errorObject); else sentContent">
        <h1 class="mat-h1 change-password-card__header">
            {{ "common.changePassword.change" | translate }}
        </h1>
        <form
                (ngSubmit)="onSubmit(changePasswordForm)"
                #changePasswordForm="ngForm"
                name="loginFrom"
                [enteraSameInputFields]="['password', 'confirmPassword']"
                [enteraSameInputErrorsFields]="['confirmPassword']"
                enteraSameInput
        >
            <mat-form-field class="change-password-card__field" [hideRequiredMarker]="true">
                <input
                        [(ngModel)]="model.password"
                        name="password"
                        matInput
                        [type]="hidePassword ? 'password' : 'text'"
                        placeholder="{{ 'common.changePassword.newPassword' | translate }}"
                        #password="ngModel"
                        required
                        minlength="8"
                        enteraComplexity
                >
                <mat-icon
                        matSuffix
                        (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="password.errors">
                    {{getErrorMessage(password)}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="change-password-card__field" [hideRequiredMarker]="true">
                <input
                        [(ngModel)]="model.confirmPassword"
                        name="confirmPassword"
                        matInput
                        [type]="hidePassword ? 'password' : 'text'"
                        placeholder="{{ 'common.changePassword.confirm' | translate }}"
                        #confirmPassword="ngModel"
                        required
                >
                <mat-icon
                        matSuffix
                        (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
                <mat-error *ngIf="confirmPassword.errors">
                    {{getErrorMessage(confirmPassword)}}
                </mat-error>
            </mat-form-field>
            <button class="change-password-card__button" type="submit" mat-raised-button color="primary">
                {{ "common.changePassword.change" | translate }}
            </button>
            <a class="change-password-card__button change-password-card__green" mat-button routerLink="/login">
                {{ "common.loginPage.login" | translate }}
            </a>
        </form>
    </div>
</entera-logo-card>
<ng-template #sentContent>
    <div *ngIf="!errorObject; else serverError">
        <h1 class="mat-h1 change-password-card__header">
            {{ "common.changePassword.successMessage" | translate }}
        </h1>
        <p class="change-password-card__note">
            {{ "common.changePassword.successDescription" | translate }}
        </p>
        <a class="change-password-card__button change-password-card__green" mat-button routerLink="/login">{{ "common.loginPage.login" | translate }}</a>
    </div>
    <ng-template #serverError>
        <h1 class="mat-h1 change-password-card__header">
            {{ "common.changePassword.failedMessage" | translate }}
        </h1>
        <p class="change-password-card__note">
            {{ errorObject.errorMessage | translate }}
        </p>
        <a class="change-password-card__button change-password-card__green" mat-button routerLink="/restore">
            {{ "common.recovery.recoverPassword" | translate }}
        </a>
    </ng-template>
</ng-template>
