import {NgModule} from "@angular/core";
import {modules} from "../common/modules";
import {services} from "../common/services";
import {providers} from "../common/providers";
import {RequestRestorePasswordComponent} from "./request-restore-password.component";
import {EnteraCommonModule} from "../common/common.module";

@NgModule({
    imports: [
        ...modules,
        EnteraCommonModule
    ],
    declarations: [
        RequestRestorePasswordComponent
    ],
    providers: [
        ...services,
        ...providers,
    ],
    exports: [
        RequestRestorePasswordComponent
    ],
})
export class RequestRestorePasswordModule {
}