import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../models";
import {Observable} from 'rxjs'
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs/internal/observable/throwError";

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private httpClient: HttpClient) {
    }

    /**
     * Вызывает логин пользователя.
     * @param {User} user Модель пользователя для логина.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    signIn(user: User): Observable<User> {
        return this.httpClient.post<User>("/api/v1/login", user)
            .pipe(
                catchError(err => throwError(err.error))
            );
    }

    /**
     * Вызывает регистрацию пользователя.
     * @param {User} user Модель пользователя для регимтрации.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    signUp(user: User): Observable<User> {
        return this.httpClient.post<User>("/api/v1/login", user)
            .pipe(
                catchError(err => throwError(err.error))
            );
    }

    /**
     * Вызывает логин пользователя.
     * @param {User} user Модель пользователя для логина.
     * @return {Observable<User>} объект асинхронного ответа.
     */
    resendVerification(user: User): Observable<User> {
        return this.httpClient.post<User>("/api/v1/registration/resend", user)
            .pipe(
                catchError(err => throwError(err.error))
            );
    }

    /**
     * Отправка запроса на воотсановление пароля.
     * @param user пользователь для которого нужно восстановить пароль.
     */
    sendRestoreRequest(user: User): Observable<any> {
        return this.httpClient.post<User>("/api/v1/restore/password", user)
            .pipe(
                catchError(err => throwError(err.error))
            );
    }


    /**
     * Отправка запроса на воотсановление пароля.
     * @param user пользователь для которого нужно восстановить пароль.
     * @param id токена запроса смены пароля.
     */
    changePassword(user: User, id: string): Observable<any> {
        return this.httpClient.post<User>(`/api/v1/restore/password/${id}`, user)
            .pipe(
                catchError(err => throwError(err.error))
            );
    }

}

