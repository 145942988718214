import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LocationService = /** @class */ (function () {
    function LocationService(router, window) {
        this.router = router;
        this.window = window;
    }
    LocationService.prototype.redirect = function (url) {
        if (url.startsWith("/") || this.isMyDomainPath(url)) {
            this.router.navigate([this.optimize(url)]);
        }
        else {
            window.location.assign(url);
        }
    };
    LocationService.prototype.isMyDomainPath = function (url) {
        return new RegExp("^http[s]?:\\/\\/(www.)?" + window.location.hostname).test(url);
    };
    LocationService.prototype.optimize = function (url) {
        return url.replace(new RegExp("^.*" + this.window.location.host), "");
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.inject(i1.Router), i0.inject("window")); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
