import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { IdEnvironment } from "src/environments/id-enviroment.model";

/**
 * Route Guard для перевода интерфейса на нужный язык.
 * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
 */
@Injectable({
    providedIn: "root"
})
export class LangGuard implements CanActivate {
    //region Fields

    /**
     * Сервис по работе с конфигурациями окружения приложения.
     */
    private _configService: ConfigurationService;

    /**
     * Сервис для работы с i18n-сообщениями.
     */
    private _translateService: TranslateService;

    //endregion
    //region Ctor

    /**
     * Конструктор Route Guard-а.
     *
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(configService: ConfigurationService, translateService: TranslateService) {

        this._configService = configService;
        this._translateService = translateService;
    }

    //endregion
    //region Public

    /**
     * Проверяет, можно ли осуществлять переход по текущему URL.
     *
     * - В данном случае, переход возможен всегда. Задача гуарда - преобразование данных.
     * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
     *
     * @return Да/нет.
     */
    canActivate(): Observable<boolean> {

        return this._configService.loadConfigurations()
            .pipe(
                map((env: IdEnvironment) => env.global ? "en" : "ru"),
                tap((lang: string): void => {

                    this._translateService.use(lang);
                }),
                map((): boolean => true),
            );
    }

    //endregion
}
