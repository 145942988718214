var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ObjectUtils } from "src/app/common/utils/object.utils";
/**
 * Production окружение.
 */
export var environment = {
    production: true,
    authServerUrl: 'https://id.entera.pro',
    appServerUrl: 'https://app.entera.pro',
    enteraLandingUrl: 'http://entera.pro',
    global: false,
};
/**
 * Выполняет загрузку конфигурации.
 */
function loadConfig() {
    var CONFIG_URL = "assets/config/config.json";
    var configRequest = new XMLHttpRequest();
    function applyConfig() {
        if (configRequest.status < 400) {
            var newEnv = __assign({}, environment, JSON.parse(configRequest.response));
            ObjectUtils.freezeInDeep(newEnv, false);
            Object.assign(environment, newEnv);
        }
    }
    configRequest.open("GET", CONFIG_URL, false);
    configRequest.setRequestHeader("Cache-Control", "no-cache");
    configRequest.onload = applyConfig;
    configRequest.send();
}
try {
    loadConfig();
}
catch (e) {
    console.log("Can't load config", e);
}
