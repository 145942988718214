import {Inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor(
        private router: Router,
        @Inject("window") private window: Window
    ) {

    }

    redirect(url: string) {
        if (url.startsWith("/") || this.isMyDomainPath(url)) {
            this.router.navigate([this.optimize(url)]);
        } else {
            window.location.assign(url);
        }
    }

    isMyDomainPath(url: string): boolean {
        return new RegExp("^http[s]?:\\/\\/(www.)?" + window.location.hostname).test(url)
    }

    optimize(url: string): string {
        return url.replace(new RegExp("^.*" + this.window.location.host), "");
    }
}
