import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParamMap } from '@angular/router';
import { Gtag } from "angular-gtag";
import { environment } from '../../environments/environment';

import { User } from '../common/models';
import { ErrorResponse } from '../common/models';
import { LoginService } from '../common/services';
import { LocationService } from '../common/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    //region Constants

    /**
     * Наименование GET-параметра для перенаправления полсе логина.
     */
    readonly REDIRECT_TO_NAME = "redirectTo";

    /**
     * Константа ошибки при которой доступно повтороая отправка письма верификации.
     */
    readonly NOT_VERIFIED_EMAIL_ERROR_CODE = "10000.10007";

    //endregion
    //region Fields

    /**
     * Окружение.
     */
    enviroment = environment;

    /**
     * GET-параметр для перенапралвения после логина.
     */
    redirectTo: string = this.enviroment.appServerUrl;

    /**
     * Модель пользователя для входа.
     */
    model: User;

    /**
     * Объект ошмбки для отображения.
     */
    errorObj: ErrorResponse = null;

    /**
     * Флаг скрытия пароля.
     */
    hidePassword = true;

    /**
     * Был ли ли вызов переотправки письма.
     */
    resent = false;

    //endregion
    //region Ctor

    constructor(
        private route: ActivatedRoute,
        private locationService: LocationService,
        private loginService: LoginService,
        private readonly _gtag: Gtag
    ) {
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this.model = new User();

        let queryParamMap = this.route.snapshot.queryParamMap;

        if (queryParamMap.has(this.REDIRECT_TO_NAME)) {

            this.redirectTo = queryParamMap.get(this.REDIRECT_TO_NAME)
        }
        this._gtag.event("sign_in_page");
    }

    //endregion
    //region Public

    /**
     * Потверждение входа пользователем.
     * @param loginForm форма логина.
     */
    onSubmit(loginForm) {
        if (loginForm.valid) {

            this._gtag.event("click_sign_in", {email: this.model.login});
            this.loginService.signIn(this.model)
                .subscribe(
                    (user) => {
                        this._gtag.event("sing_in_successfully");
                        setTimeout(() => this.locationService.redirect(this.redirectTo), 50);
                    },
                    (error) => this.errorObj = error);
            this.resent = false;
        }

    }

    /**
     * Ести ли ошибки на текщий момент в форме.
     */
    hasError(): boolean {
        return this.errorObj != null;
    }

    /**
     * Запрос повторной отправки письма верификации.
     * @param event событие клика мыши
     */
    resendVerificationEmail(event: Event) {
        event.preventDefault();
        this.resent = true;
        this.loginService.resendVerification(this.model)
            .subscribe(
                (user) => {},
                (error) => this.errorObj = error);
    }

    /**
     * Возвращает адрес регистрации с имеющимися utm-метками.
     */
    getRegistrationUrl() {

        let queryParamMap: ParamMap = this.route.snapshot.queryParamMap;
        const utmParams: string = queryParamMap.keys
            .filter((key: string): boolean => key.startsWith('utm_'))
            .map((key: string): string => key + '=' + encodeURIComponent(queryParamMap.get(key)))
            .join('&');

        let href: string = this.enviroment.appServerUrl + '/registration';

        if (utmParams) {

            href += '?' + utmParams;
        }

        return href;
    }

    /**
     * Обработчик нажатия на ссылку регистрации.
     */
    signUpHandler() {

        this._gtag.event("click_to_sign_up");
        const timeout = setTimeout(() => this.locationService.redirect(this.getRegistrationUrl()), 150);
    }

    //endregion
}