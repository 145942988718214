import { environment } from "src/environments/environment";
import * as LogRocket from "logrocket";
import * as i0 from "@angular/core";
/**
 * Сервис для инициализации Logrocket'а.
 */
var LogrocketService = /** @class */ (function () {
    function LogrocketService() {
    }
    //region Public
    /**
     * Инициализирует Logrocket.
     *
     * В процессе инициализации передаёт App ID LogRocket'а и идентифицирует пользователя.
     */
    LogrocketService.prototype.init = function () {
        if (environment.logRocket && environment.logRocket.enabled) {
            LogRocket.init(environment.logRocket.appId);
        }
    };
    LogrocketService.ngInjectableDef = i0.defineInjectable({ factory: function LogrocketService_Factory() { return new LogrocketService(); }, token: LogrocketService, providedIn: "root" });
    return LogrocketService;
}());
export { LogrocketService };
