import {Component, OnInit} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import {ErrorResponse, User} from "../common/models";
import {LoginService} from "../common/services";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'app-login',
    templateUrl: './request-restore-password.component.html',
    styleUrls: ['./request-restore-password.component.scss']
})
export class RequestRestorePasswordComponent implements OnInit {

    //region Fields

    /**
     * Модель пользователя для входа.
     */
    model: User;

    /**
     * Сосмтояние запроса (отправлен/нет).
     */
    sent = false;

    /**
     * Сервис для работы с i18n-сообщениями.
     */
    private _translateService: TranslateService;

    //endregion
    //region C-tor & Hooks

    /**
     * Конструктор компоненты восстановления пароля.
     *
     * @param loginService Сервис для входа в систему и выхода из системы.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(
        private loginService: LoginService,
        translateService: TranslateService,
    ) {
        this._translateService = translateService;
    }

    ngOnInit(): void {

        this.model = new User();
    }

    //endregion
    //region Public

    /**
     * Подтверждение отправки запроса на восстановление пароля.
     *
     * @param form Форма запроса восстановления пароля.
     */
    onSubmit(form: NgForm) {

        const emailControl = form.controls.email;

        if (form.valid || emailControl.hasError("serverError")) {

            form.control.disable();

            this.loginService.sendRestoreRequest(this.model).subscribe(
                () => this.sent = true,
                (error) => {
                    form.control.enable();
                    emailControl.setErrors({serverError: error});
                }
            );
        }

    }

    /**
     * Текст ошибки на форме.
     */
    getError(form: NgForm): string {

        const errors = form.controls.email.errors;
        let result: string;

        if (errors["serverError"]) {

            result = (errors["serverError"] as ErrorResponse).errorMessage;
        } else if (errors["email"]) {

            result = this._translateService.instant("common.errors.incorrectEmail");
        } else if (errors["required"]) {

            result = this._translateService.instant("common.errors.emailRequired");
        }

        return result;
    }

    // endregion

}
