import { APP_INITIALIZER } from "@angular/core";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { matDateFormats, matMomentDateAdapterOptionsProvider } from "./mat-datepicker.provider";
import { Title } from "@angular/platform-browser";


/**
 * Возвращает функцию для получения конфигурации.
 */
export function getConfiguration(configService: ConfigurationService): Function {

    return () => configService.loadConfigurations().toPromise();
}

/**
 * Провайдеры, которые необходимо зарегистрировать в общем модуле.
 */
export const providers = [
    matMomentDateAdapterOptionsProvider,
    matDateFormats,
    Title,
    {
        provide: "window",
        useValue: window,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: getConfiguration,
        deps: [ConfigurationService],
        multi: true,
    },
];
