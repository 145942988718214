import {NgModule} from "@angular/core";
import {modules} from "../common/modules";
import {services} from "../common/services";
import {providers} from "../common/providers";
import {LoginComponent} from "./login.component";
import {EnteraCommonModule} from "../common/common.module";

@NgModule({
    imports: [
        ...modules,
        EnteraCommonModule
    ],
    declarations: [
        LoginComponent
    ],
    providers: [
        ...services,
        ...providers,
    ],
    exports: [
        LoginComponent
    ],
})
export class LoginModule {
}