import { OnInit } from "@angular/core";
import {Component} from '@angular/core';
import { LogrocketService } from "src/app/root/services/logrocket.service";

@Component({
    selector: 'id-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
    //region Fields

    /**
     * Сервис для инициализации Logrocket'а.
     */
    private readonly _logrocketService: LogrocketService;

    //endregion
    //region Ctor

    /**
     * Конструктор корневого компонента.
     *
     * @param logrocketService Сервис для инициализации Logrocket'а.
     */
    constructor(logrocketService: LogrocketService) {

        this._logrocketService = logrocketService;
    }

    //endregion
    //region Hooks

    /**
     * Логика, выполняющаяся при инициализации компонента.
     */
    ngOnInit() {

        this._logrocketService.init();
    }

    //endregion
}
