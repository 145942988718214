import {NgModule} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { langs } from "src/app/common/i18n/langs";
import { LangService } from "src/app/common/services/lang.service";

import {modules} from "./modules";
import {modulesForExport} from "./modules";
import {components, entryComponents} from "./components";
import {services} from "./services";
import {pipes} from "./pipes";
import {providers} from "./providers";
import {validators} from "./validators";
import {directives} from "./directivies";

@NgModule({
    imports: [
        ...modules
    ],
    declarations: [
        ...components,
        ...directives,
        ...pipes,
        ...validators,
    ],
    providers: [
        ...services,
        ...providers,
    ],
    exports: [
        ...modulesForExport,
        ...components,
        ...directives,
        ...pipes,
        ...validators,
    ],
    entryComponents: [
        ...entryComponents
    ]
})
export class EnteraCommonModule {

    /**
     * Конструктор модуля.
     *
     * @param translateService Сервис для работы с i18n-сообщениями.
     * @param langService Сервис для работы с языками.
     */
    constructor(
        private translateService: TranslateService,
        private langService: LangService,
    ) {
        this.langService.loadTranslations(this.translateService, langs);
    }
}
