import {Component, OnInit} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import {User} from "../common/models";
import {LoginService} from "../common/services";
import {NgForm} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {NgModel} from "@angular/forms";
import {ErrorResponse} from "../common/models";

@Component({
    selector: 'app-login',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    //region Fields

    /**
     * Модель пользователя для входа.
     */
    model: User;

    /**
     * Код токена восстановления пароля.
     */
    code: string;

    /**
     * Флаг скрытия пароля.
     */
    hidePassword = true;

    /**
     * Сосмтояние запроса (отправлен/нет).
     */
    sent = false;

    /**
     * Ошибка при отправке на сервер
     */
    errorObject: ErrorResponse;

    /**
     * Сервис для работы с i18n-сообщениями.
     */
    private _translateService: TranslateService;

    //endregion
    //region C-tor & Hooks

    /**
     * Конструктор компоненты смены пароля.
     *
     * @param loginService Сервис для входа в систему и выхода из системы.
     * @param route Состояние текущего пути.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        translateService: TranslateService,
    ) {
        this._translateService = translateService;
    }

    ngOnInit(): void {

        this.model = new User();
        this.code = this.route.snapshot.paramMap.get("id");
    }

    //endregion
    //region Public

    /**
     * Потверждение отправки запроса на восстановлденеи пароля.
     * @param form форма запроса восстановления пароля.
     */
    onSubmit(form: NgForm) {
        if (form.valid) {
            form.control.disable();
            this.loginService.changePassword(this.model, this.code)
                .subscribe(
                    () => this.sent = true,
                    (error) => this.errorObject = error);
        }

    }

    /**
     * Текст ошибки на форме.
     */
    getErrorMessage(model: NgModel): string {

        let errors = model.control.errors;
        let result: string;

        if (errors["required"]) {

            result = this._translateService.instant("common.errors.required");
        } else if (errors["minlength"]) {

            result = this._translateService.instant("common.errors.characterCount");
        } else if (errors["complexity"]) {

            result = this._translateService.instant("common.errors.characterDifference");
        } else if (errors["notSame"]) {

            result = this._translateService.instant("common.errors.notSamePasswords");
        }

        return result;
    }

    //endregion

}
