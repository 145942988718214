import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { version } from "src/environments/version"

/**
 * Комонент страницы с версией UI-приложения.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'version-page',
    styleUrls: ['./version.component.scss'],
    templateUrl: './version.component.html',
})
export class VersionComponent implements OnInit, OnDestroy {
    //#region Ctor

    constructor(
        private _title: Title
    ) { }

    //#endregion
    //#region Hooks

    ngOnInit(): void {

        this._title.setTitle("Entera - version");
    }

    ngOnDestroy(): void {

        this._title.setTitle("");
    }

    //#endregion
    //#region Getters and Setter

    /**
     * Возвращает версию UI приложения.
     */
    get version(): string {

        return version;
    }

    //#endregion
}