import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { Routes } from "@angular/router";
import { LangGuard } from "src/app/root/guards/lang-guard.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";

import { LoginComponent } from "../login/login.component";
import { RequestRestorePasswordComponent } from "../request-restore-password/request-restore-password.component";
import { VersionComponent } from "./containers/version/version.component";


export const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
        canActivate: [LangGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LangGuard],
    },
    {
        path: 'restore',
        component: RequestRestorePasswordComponent,
        canActivate: [LangGuard],
    },
    {
        path: 'restore/:id',
        component: ChangePasswordComponent,
        canActivate: [LangGuard],
    },
    {
        path: 'version',
        component: VersionComponent
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class RootRoutingModule { }
