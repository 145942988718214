import * as i0 from "@angular/core";
/**
 * Сервис для работы с языками.
 */
var LangService = /** @class */ (function () {
    function LangService() {
    }
    //region Public
    /**
     * Выполняет загрузку заданных сообщений в сервис для работы с i18n-сообщениями.
     *
     * @param translateService Сервис для работы с i18n-сообщениями.
     * @param langs Сообщения на разных языках.
     */
    LangService.prototype.loadTranslations = function (translateService, langs) {
        for (var _i = 0, langs_1 = langs; _i < langs_1.length; _i++) {
            var lang = langs_1[_i];
            translateService.setTranslation(lang.lang, lang.messages, true);
        }
    };
    LangService.ngInjectableDef = i0.defineInjectable({ factory: function LangService_Factory() { return new LangService(); }, token: LangService, providedIn: "root" });
    return LangService;
}());
export { LangService };
