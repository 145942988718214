<entera-logo-card class="login-card">
    <div>
        <h1 class="mat-h1 title center">
            {{ "common.loginPage.enteraLogin" | translate }}
        </h1>
        <form (ngSubmit)="onSubmit(loginForm)" #loginForm="ngForm" name="loginFrom">
            <mat-form-field class="login-card__field">
                <input
                        [(ngModel)]="model.login"
                        (input)="errorObj = null"
                        name="email"
                        matInput
                        type="email"
                        placeholder="Email"
                        required
                >
            </mat-form-field>
            <mat-form-field class="login-card__field">
                <input
                        [(ngModel)]="model.password"
                        (input)="errorObj = null"
                        name="password"
                        matInput
                        [type] = "hidePassword ? 'password' : 'text'"
                        placeholder="{{ 'common.loginPage.password' | translate }}"
                        required
                >
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            </mat-form-field>
            <mat-error class="center error" *ngIf="hasError()">
                {{errorObj.errorMessage}}
                <div class="login-card__resend" *ngIf="errorObj?.errorCode == NOT_VERIFIED_EMAIL_ERROR_CODE && !resent">
                    <a class="login-card__link" (click) = resendVerificationEmail($event)>
                        {{ "common.loginPage.resendVerificationEmail" | translate }}
                    </a>
                </div>
            </mat-error>
            <div class="login-card__restore-link-container">
                <a class="login-card__restore-link" routerLink="/restore">{{ "common.loginPage.forgotPassword" | translate }}</a>
            </div>
            <button class="login-card__button enter-btn" type="submit" mat-raised-button color="primary">{{ "common.loginPage.login" | translate }}</button>
        </form>
        <h4 class="center footer mat-h4">
            {{ "common.loginPage.dontHaveAccount" | translate }}
            <a class="login-card__link" (click)="signUpHandler()">{{"common.loginPage.signUp" | translate}}</a>
        </h4>
    </div>
</entera-logo-card>
