/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./logo-card.component";
var styles_LogoCardComponent = [i0.styles];
var RenderType_LogoCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoCardComponent, data: {} });
export { RenderType_LogoCardComponent as RenderType_LogoCardComponent };
export function View_LogoCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "logo-card mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo-card__logo"], ["src", "assets/images/logo.svg"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_LogoCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entera-logo-card", [], null, null, null, View_LogoCardComponent_0, RenderType_LogoCardComponent)), i1.ɵdid(1, 114688, null, 0, i4.LogoCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoCardComponentNgFactory = i1.ɵccf("entera-logo-card", i4.LogoCardComponent, View_LogoCardComponent_Host_0, {}, {}, ["*"]);
export { LogoCardComponentNgFactory as LogoCardComponentNgFactory };
