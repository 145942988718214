import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IdEnvironment } from "src/environments/id-enviroment.model";

/**
 * Сервис по работе с конфигурациями окружения приложения.
 */
@Injectable({ providedIn: "root" })
export class ConfigurationService {
    //region Fields

    /**
     * Конфигурация окружения приложения.
     */
    private readonly configuration$: Observable<IdEnvironment>;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса по работе с конфигурациями окружения приложения.
     *
     */
    constructor() {

        this.configuration$ = of(environment);
    }

    //endregion
    //region Public

    /**
     * Загружает конфигурацию окружения приложения.
     *
     * @return Конфигурация окружения приложения.
     */
    public loadConfigurations(): Observable<IdEnvironment> {

        return this.configuration$;
    }

    //endregion
}
