import { TranslateService } from "@ngx-translate/core";
import { tap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import * as i0 from "@angular/core";
import * as i1 from "../../common/services/configuration.service";
import * as i2 from "@ngx-translate/core";
/**
 * Route Guard для перевода интерфейса на нужный язык.
 * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
 */
var LangGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор Route Guard-а.
     *
     * @param configService Сервис по работе с конфигурациями окружения приложения.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    function LangGuard(configService, translateService) {
        this._configService = configService;
        this._translateService = translateService;
    }
    //endregion
    //region Public
    /**
     * Проверяет, можно ли осуществлять переход по текущему URL.
     *
     * - В данном случае, переход возможен всегда. Задача гуарда - преобразование данных.
     * - Проверяет флаг global в окружении. Если он true, то выставляет английский язык. Иначе - русский.
     *
     * @return Да/нет.
     */
    LangGuard.prototype.canActivate = function () {
        var _this = this;
        return this._configService.loadConfigurations()
            .pipe(map(function (env) { return env.global ? "en" : "ru"; }), tap(function (lang) {
            _this._translateService.use(lang);
        }), map(function () { return true; }));
    };
    LangGuard.ngInjectableDef = i0.defineInjectable({ factory: function LangGuard_Factory() { return new LangGuard(i0.inject(i1.ConfigurationService), i0.inject(i2.TranslateService)); }, token: LangGuard, providedIn: "root" });
    return LangGuard;
}());
export { LangGuard };
